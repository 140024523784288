$color-mode-type: media-query;

@import '~bootstrap/scss/bootstrap';

.github-logo {
  background: no-repeat center/cover url(/github-mark.svg);
}

@include color-mode(dark) {
  .github-logo {
    background-image: url(/github-mark-white.svg);
  }
}

.form-login {
  max-width: 400px;
  padding: 1rem;

  .form-floating:focus-within {
    z-index: 2;
  }

  input[type='email'] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  input[type='password'] {
    margin-bottom: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
